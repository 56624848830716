import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import Label from '../Label';

RHFAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      exchange: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default function RHFAutoComplete({ name, options, label, placeholder, multiple = true, ...other }) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            field.onChange(newValue);
          }}
          multiple={multiple}
          options={options}
          getOptionLabel={(option) => `${option.name} ${option.exchange.name}`}
          isOptionEqualToValue={(option, value) =>
            option.name.toString() === value.name.toString() &&
            option.exchange.name.toString() === value.exchange.name.toString()
          }
          disableCloseOnSelect
          value={field.value || []}
          renderOption={(props, option) => (
            <Box component="li" {...props} display="flex" justifyContent="space-between">
              <Typography sx={{ mx: 1 }} variant="body1">
                {option.name}
              </Typography>
              <Label variant="ghost" color="info">
                {option.exchange.name}
              </Label>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              variant="outlined"
              label={label || 'Multiple Autocomplete'}
              placeholder={placeholder || 'Multiple Autocomplete'}
              {...other}
            />
          )}
        />
      )}
    />
  );
}
