import { Bookmark, Work, Summarize, History } from '@mui/icons-material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  loan: getIcon('ic_loan'),
  userList: getIcon('ic_user_list'),
};

const clientNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      { title: 'watchlist', path: PATH_DASHBOARD.bankerRole.watchlist.root, icon: <Bookmark /> },
      // { title: 'TradingView', path: PATH_DASHBOARD.bankerRole.tradingView.root, icon: <PlaylistAddCheckCircle /> },
      { title: 'Trades', path: PATH_DASHBOARD.bankerRole.trades.root, icon: <Summarize /> },
      { title: 'Portfolio', path: PATH_DASHBOARD.bankerRole.portfolio.root, icon: <Work /> },
       { title: 'History', path: PATH_DASHBOARD.bankerRole.history.root, icon: <History /> },
      { title: 'profile', path: PATH_DASHBOARD.bankerRole.profile.root, icon: ICONS.user },
      // { title: 'chat', path: PATH_DASHBOARD.bankerRole.chat.root, icon: ICONS.chat },
    ],
  },
  // {
  //   subheader: 'accounts',
  //   items: [{ title: 'profile', path: PATH_DASHBOARD.bankerRole.profile.root, icon: ICONS.user }],
  // },
];

export default clientNavConfig;
