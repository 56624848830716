import {
  Dashboard,
  People,
  Assessment,
  Settings,
  AccountCircle,
  LockReset,
  Assignment,
  Bookmark,
  Tune,
  AssignmentTurnedIn,
  HourglassBottom,
  Group,
  SwitchAccount,
  ViewList,
  PlayArrow,
  Stop,
  Security,
  Summarize,
  // DateRange,
  Troubleshoot,
  Difference,
  CloudDownload,
  NoEncryptionGmailerrorred,
} from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  dashboard: <Dashboard />,
  insurance: getIcon('ic_insurance'),
  userList: <People />,
  tradeList: <Assessment />,
  activityLogs: <Assignment />,
  settings: <Settings />,
  profile: <AccountCircle />,
  watchlist: <Bookmark />,
  weekelyReports: <CloudDownload />,
  equityLotSettings: <Tune />,
  generalSettings: <Settings />,
  executedTrades: <AssignmentTurnedIn />,
  pendingTrades: <HourglassBottom />,
  changePassword: <LockReset />,
  lineTrades: <Security />,
  clientUsers: <Group />,
  brokerUsers: <SwitchAccount />,
  positions: <ViewList />,
  activeTrades: <PlayArrow />,
  closedTrades: <Stop />,
  reports: <Summarize />,
  riskReport: <Troubleshoot />,
  plHistory: <Difference />,
  bansSctipts: <NoEncryptionGmailerrorred />,
};

const brokerNavConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.operatorRole.general.app, icon: ICONS.dashboard },
      { title: 'watchlist', path: PATH_DASHBOARD.operatorRole.watchlist.root, icon: ICONS.watchlist },
      { title: 'activity logs', path: PATH_DASHBOARD.operatorRole.log.root, icon: ICONS.activityLogs },
      { title: 'Clients', path: PATH_DASHBOARD.operatorRole.clients.root, icon: ICONS.clientUsers },
      {
        title: 'Positions',
        icon: ICONS.positions,
        path: PATH_DASHBOARD.operatorRole.positions.grouped.root,
        children: [
          { title: 'Active', path: PATH_DASHBOARD.operatorRole.positions.grouped.active, icon: ICONS.activeTrades },
          { title: 'Closed', path: PATH_DASHBOARD.operatorRole.positions.grouped.closed, icon: ICONS.closedTrades },
        ],
      },
      {
        title: 'trades',
        icon: ICONS.tradeList,
        // path: PATH_DASHBOARD.operatorRole.positions.grouped.root,
        children: [
          { title: 'Executed', path: PATH_DASHBOARD.operatorRole.trades.executed, icon: ICONS.executedTrades },
          { title: 'Pending', path: PATH_DASHBOARD.operatorRole.trades.pending, icon: ICONS.pendingTrades },
        ],
      },
      {
        title: 'settings',
        icon: ICONS.settings,
        children: [
          {
            title: 'Equity Lot Settings',
            path: PATH_DASHBOARD.operatorRole.setting.equity,
            icon: ICONS.equityLotSettings,
          },
          // { title: 'General settings', path: PATH_DASHBOARD.operatorRole.setting.general, icon: ICONS.generalSettings },
          {
            title: 'Change Password',
            path: PATH_DASHBOARD.operatorRole.setting.changePassword,
            icon: ICONS.changePassword,
          },
        ],
      },
      {
        title: 'Reports',
        icon: ICONS.reports,
        children: [
          {
            title: 'P&L Summary',
            path: PATH_DASHBOARD.operatorRole.report.plHistory,
            icon: ICONS.plHistory,
          },
          {
            title: 'Ban Scripts',
            path: PATH_DASHBOARD.operatorRole.report.banScripts,
            icon: ICONS.bansSctipts,
          },
          { title: 'Alert Trades', path: PATH_DASHBOARD.operatorRole.lineTrades.root, icon: ICONS.lineTrades },
          {
            title: 'Risk Report',
            path: PATH_DASHBOARD.operatorRole.report.riskyUser,
            icon: ICONS.riskReport,
          },
          {
            title: 'Weekly Settlement',
            path: PATH_DASHBOARD.operatorRole.report.weekly,
            icon: ICONS.weekelyReports,
          },
          { title: 'Alert Trades', path: PATH_DASHBOARD.operatorRole.lineTrades.root, icon: ICONS.lineTrades },
          // { title: 'General settings', path: PATH_DASHBOARD.insurerRole.setting.general, icon: ICONS.generalSettings },
        ],
      },
    ],
  },
  // {
  //   subheader: 'accounts',
  //   items: [
  //     // { title: 'profile', path: PATH_DASHBOARD.operatorRole.profile.root, icon: ICONS.user },
  //     { title: 'watchlist', path: PATH_DASHBOARD.operatorRole.watchlist.root, icon: ICONS.watchlist },
  //   ],
  // },
];

export default brokerNavConfig;
