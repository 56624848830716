import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSnackbar } from 'notistack';
import axios from '../../../utils/axios';

const PriceChangeConfig = () => {
  const [configData, setConfigData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [newExchange, setNewExchange] = useState('');
  const [newScript, setNewScript] = useState('');
  const [newPrice, setNewPrice] = useState('');
  const [priceUpdates, setPriceUpdates] = useState({});
  const [loadingStates, setLoadingStates] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchPriceChangeConfig();
  }, []);

  const fetchPriceChangeConfig = async () => {
    try {
      const { data } = await axios.get('/user-config/get-price-change-config');
      setConfigData(data.data);
      // Initialize price updates with current values
      const updates = {};
      Object.keys(data.data).forEach((exchange) => {
        updates[exchange] = {};
        Object.keys(data.data[exchange]).forEach((script) => {
          updates[exchange][script] = data.data[exchange][script];
        });
      });
      setPriceUpdates(updates);
    } catch (error) {
      console.error('Error fetching config:', error);
    }
  };

  const handlePriceUpdate = async (exchange, scriptName, newPrice) => {
    setLoadingStates((prev) => ({
      ...prev,
      [`${exchange}-${scriptName}`]: true,
    }));

    try {
      await axios.post('/user-config/update-price-change-config', {
        exchange,
        scriptName,
        price: newPrice,
      });
      await fetchPriceChangeConfig();
      enqueueSnackbar('Price updated successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Error updating price:', error);
      enqueueSnackbar(error?.data?.message || 'Error updating price', { variant: 'error' });
    } finally {
      setLoadingStates((prev) => ({
        ...prev,
        [`${exchange}-${scriptName}`]: false,
      }));
    }
  };

  const handlePriceChange = (exchange, scriptName, value) => {
    setPriceUpdates((prev) => ({
      ...prev,
      [exchange]: {
        ...prev[exchange],
        [scriptName]: value,
      },
    }));
  };

  const isUpdateEnabled = (exchange, scriptName) =>
    priceUpdates[exchange]?.[scriptName] !== configData[exchange]?.[scriptName];

  const handleAddNew = async () => {
    if (!newExchange || !newScript || !newPrice) return;

    try {
      await axios.post('/user-config/update-price-change-config', {
        exchange: newExchange,
        scriptName: newScript,
        price: newPrice,
      });
      setOpenDialog(false);
      setNewExchange('');
      setNewScript('');
      setNewPrice('');
      enqueueSnackbar('Script inserted successfully!', { variant: 'success' });
      fetchPriceChangeConfig();
    } catch (error) {
      console.error('Error adding new script:', error);
      enqueueSnackbar(error?.data?.message || 'Error inserting script', { variant: 'error' });
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button variant="contained" sx={{ mb: 2 }} onClick={() => setOpenDialog(true)}>
        Add New Script
      </Button>

      {Object.keys(configData).map((exchange) => (
        <Accordion key={exchange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{exchange}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(configData[exchange]).map((scriptName) => (
              <Box
                key={scriptName}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  gap: 1,
                  mb: 1,
                  borderRadius: 1,
                  alignItems: 'start',
                }}
              >
                <Typography sx={{ textAlign: 'left', my: 1 }}>{scriptName}</Typography>
                <TextField
                  size="small"
                  type="number"
                  value={priceUpdates[exchange]?.[scriptName] || ''}
                  placeholder="New Price"
                  fullWidth
                  onChange={(e) => handlePriceChange(exchange, scriptName, e.target.value)}
                />
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!isUpdateEnabled(exchange, scriptName) || loadingStates[`${exchange}-${scriptName}`]}
                  sx={{ p: 1 }}
                  onClick={() => handlePriceUpdate(exchange, scriptName, priceUpdates[exchange][scriptName])}
                >
                  {loadingStates[`${exchange}-${scriptName}`] ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Update'
                  )}
                </Button>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Script</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Exchange"
            value={newExchange}
            onChange={(e) => setNewExchange(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Script Name"
            value={newScript}
            onChange={(e) => setNewScript(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Price"
            type="number"
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddNew} variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}>
          {snackbar.message}
        </Alert>
      </Snackbar> */}
    </Box>
  );
};

export default PriceChangeConfig;
