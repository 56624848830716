/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { TableRow, TableCell, Typography, IconButton, Tooltip, Box } from '@mui/material';
import { useState } from 'react';
import { Add, MonetizationOn } from '@mui/icons-material'; // Import Add icon
import { InfoIcon } from '../../../theme/overrides/CustomIcons';
import AddBalanceModal from './AddBalanceModal';
import AddManualTradeModal from './AddManualTradeModal'; // Import the new modal component
import { fColorNumber, fNumber } from '../../../utils/formatNumber';
import UserConfigInfo from './UserConfigInfo';

// eslint-disable-next-line react/prop-types
// const TwoValueDisplay = ({ primaryValue, secondaryValue, primaryColor, secondaryColor, onClick }) => (
//   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }} onClick={onClick}>
//     <Typography variant="h7" color={primaryColor || 'text.primary'}>
//       {primaryValue}
//     </Typography>
//     <Typography variant="caption2" color={secondaryColor || 'textSecondary'}>
//       {secondaryValue}
//     </Typography>
//   </Box>
// );

// const ThreeValueDisplay = ({
//   primaryValue,
//   secondaryValue,
//   thirdValue,
//   primaryColor,
//   secondaryColor,
//   thirdColor,
//   onClick,
// }) => (
//   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer' }} onClick={onClick}>
//     <Typography variant="h7" color={primaryColor || 'text.primary'}>
//       {primaryValue}
//     </Typography>
//     <Typography variant="caption2" color={secondaryColor || 'textSecondary'}>
//       {secondaryValue}
//     </Typography>
//     <Typography variant="caption2" color={thirdColor || 'textSecondary'}>
//       {thirdValue}
//     </Typography>
//   </Box>
// );

const ColoredTypography = ({ value }) => (
  <Typography color={fColorNumber(value || 0)}>{fNumber(value || 0)}</Typography>
);

UsersBalanceSummaryRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  role: PropTypes.oneOf(['broker', 'non-broker']).isRequired, // Define role prop
  onViewRow: PropTypes.func,
  onUserUpdate: PropTypes.func,
  loggedInUser: PropTypes.object,
};

export default function UsersBalanceSummaryRow({ row, selected, role, onViewRow, loggedInUser, onUserUpdate }) {
  // Extract fields from the row for usage
  const {
    trader,
    totalProfit,
    totalBrokerage,
    blockedMargin,
    activeProfitLoss,
    balance,
    grossPl,
    refundBrokerage,
    yourProfitLoss,
    yourBrokerage,
    yourActivePl,
    yourGrossPl,
  } = row;
  const [openBalanceModal, setOpenBalanceModal] = useState(false);
  const [openTradeModal, setOpenTradeModal] = useState(false); // State for the trade modal

  const handleOpenBalanceModal = () => {
    setOpenBalanceModal(true);
  };

  const handleCloseBalanceModal = () => {
    setOpenBalanceModal(false);
  };

  const handleOpenTradeModal = () => {
    setOpenTradeModal(true);
  };

  const handleCloseTradeModal = () => {
    setOpenTradeModal(false);
  };

  const [openInfoModal, setOpenInfoModal] = useState(false);

  const handleOpenInfoModal = () => setOpenInfoModal(true);
  const handleCloseInfoModal = () => setOpenInfoModal(false);

  return (
    <>
      <TableRow hover selected={selected}>
        {/* Button for adding balance, only for brokers */}
        {role === 'broker' && (
          <TableCell align="right" sx={{ display: 'flex', flexDirection: 'row' }}>
            {loggedInUser?.role?.rolename !== 'superAdmin' && (
              <Tooltip title="Add Balance">
                <IconButton onClick={handleOpenBalanceModal}>
                  <MonetizationOn color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {loggedInUser?.role?.rolename === 'admin' && (
              <Tooltip title="Add Trade">
                <IconButton onClick={handleOpenTradeModal}>
                  <Add color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}

        <TableCell
          align="left"
          style={{
            width: '15%',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            textTransform: 'uppercase',
            position: 'sticky',
            left: 0,
            // zIndex: 1,
            backdropFilter: 'blur(100px)', // Adds a blur effect to the background
            WebkitBackdropFilter: 'blur(100px)', // Safari support
          }}
          onClick={onViewRow}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography variant="body1" color="text.primary">
              {role === 'broker' ? (
                <>
                  {trader?.username}
                  {trader?.isDemoAccount === true && (
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        backgroundColor: '#f0f0f0', // light gray background
                        color: 'primary.light',
                        padding: '3px 5px',
                        marginLeft: '2px',
                        borderRadius: '10px',
                        fontSize: '0.60rem',
                        fontWeight: 'bold',
                        border: '2px solid #ccc',
                      }}
                    >
                      DEMO
                    </Box>
                  )}
                </>
              ) : (
                trader?.username
              )}
            </Typography>

            {(trader?.firstname || trader?.lastname) && (
              <Typography variant="caption2" color="textSecondary" sx={{ whiteSpace: 'break-spaces' }}>
                {`(${trader?.firstname} ${trader?.lastname})`}
              </Typography>
            )}
          </Box>
        </TableCell>

        {/* Conditionally render fields based on the role */}
        {role === 'broker' && (
          <>
            <TableCell align="left" style={{ width: '15%' }}>
              {fNumber(balance?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%' }}>
              {fNumber(blockedMargin?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%', color: fColorNumber(grossPl?.toFixed(0) || 0) }}>
              {fNumber(grossPl?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%', color: fColorNumber(activeProfitLoss?.toFixed(0) || 0) }}>
              {fNumber(activeProfitLoss?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%', color: fColorNumber(totalProfit?.toFixed(0) || 0) }}>
              {fNumber(totalProfit?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%' }}>
              {fNumber(totalBrokerage?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="left" style={{ width: '15%' }}>
              {fNumber(refundBrokerage?.toFixed(2) || '0.00')}
            </TableCell>
            <TableCell align="center">
              <Tooltip title="CLIENT SETTING INFO">
                <IconButton onClick={handleOpenInfoModal}>
                  <InfoIcon color="primary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </>
        )}

        {/* Only displayed for non-broker roles */}
        {role === 'non-broker' && (
          <>
            <TableCell align="left" onClick={onViewRow}>
              {/* <ThreeValueDisplay
                primaryValue={fNumber(yourGrossPl || '0.00')}
                secondaryValue={fNumber(superAdminGrossPl || '0.00')}
                thirdValue={fNumber(grossPl || '0.00')}
                primaryColor={fColorNumber(grossPl || 0)}
                onClick={onViewRow}
              /> */}
              {/* {fNumber(yourGrossPl?.toFixed(2) || '0.00')} */}
              <ColoredTypography value={yourGrossPl} />
            </TableCell>

            <TableCell align="left">
              {/* <ThreeValueDisplay
                primaryValue={fNumber(yourActivePl || '0.00')}
                secondaryValue={fNumber(superAdminActivePl || '0.00')}
                thirdValue={fNumber(activeProfitLoss || '0.00')}
                primaryColor={fColorNumber(activeProfitLoss || 0)}
                onClick={onViewRow}
              /> */}
              <ColoredTypography value={yourActivePl} />
            </TableCell>

            <TableCell align="left">
              {/* <ThreeValueDisplay
                primaryValue={fNumber(yourProfitLoss || '0.00')}
                secondaryValue={fNumber(superAdminProfit || '0.00')}
                thirdValue={fNumber(totalProfit || '0.00')}
                primaryColor={fColorNumber(yourProfitLoss || 0)}
                onClick={onViewRow}
              /> */}
              <ColoredTypography value={yourProfitLoss} />
            </TableCell>

            <TableCell align="left">
              {/* <ThreeValueDisplay
                primaryValue={fNumber(yourBrokerage || '0.00')}
                secondaryValue={fNumber(superAdminBrokerage || '0.00')}
                thirdValue={fNumber(totalBrokerage || '0.00')}
                onClick={onViewRow}
              /> */}
              <ColoredTypography value={yourBrokerage} />
            </TableCell>
            <TableCell align="left" style={{ width: '15%' }}>
              {fNumber(refundBrokerage?.toFixed(2) || '0.00')}
            </TableCell>

            <TableCell align="center">
              <Tooltip title="BROKER SETTINGS INFO">
                <IconButton onClick={handleOpenInfoModal}>
                  <InfoIcon color="primary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </>
        )}
      </TableRow>

      {openBalanceModal && (
        <AddBalanceModal
          open={openBalanceModal}
          balanceSummary={row}
          onClose={handleCloseBalanceModal}
          onUserUpdate={onUserUpdate}
        />
      )}

      {/* Add the new modal for manual trade */}
      {openTradeModal && (
        <AddManualTradeModal
          open={openTradeModal}
          client={trader}
          onClose={handleCloseTradeModal}
          onUserUpdate={onUserUpdate}
        />
      )}
      <UserConfigInfo
        open={openInfoModal}
        onClose={handleCloseInfoModal}
        userName={trader?.username}
        traderConfigs={trader?.config}
        role={role}
      />
    </>
  );
}
