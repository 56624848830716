/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Card,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
} from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// // hooks
// import useAuth from '../../../../hooks/useAuth';
// // utils
// import { fData } from '../../../../utils/formatNumber';
// // _mock
// import { countries } from '../../../../_mock';
// // components
// import {
//   FormProvider,
//   // RHFSwitch,
//   RHFSelect,
//   RHFTextField,
//   RHFUploadAvatar,
// } from '../../../../components/hook-form';

import ExchangeMargin from './ExchangeMargin';
import useResponsive from '../../../../hooks/useResponsive';

// ----------------------------------------------------------------------

export default function AccountConfig({ user }) {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });
  const defaultValues = {
    displayName: 'Mosam Gor' || '',
    email: 'mosam@gor.com' || '',
    phoneNumber: '8785002234' || '',
    country: 'India' || '',
    address: 'Isanpur' || '',
    state: 'Gujarat' || '',
    city: 'Ahmedabad' || '',
    zipCode: '380025' || '',
    about: '' || '',
    // isPublic: admin?.isPublic || false,
    // displayName: admin?.displayName || '',
    // email: admin?.email || '',
    // photoURL: admin?.photoURL || '',
    // phoneNumber: admin?.phoneNumber || '',
    // country: admin?.country || '',
    // address: admin?.address || '',
    // state: admin?.state || '',
    // city: admin?.city || '',
    // zipCode: admin?.zipCode || '',
    // about: admin?.about || '',
    // isPublic: admin?.isPublic || false,
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <>
      <Card
        sx={{
          p: 1,
          background: theme.palette.mode === 'dark' ? '#121212' : '#fff',
          borderRadius: '15px',
          boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box>
          <TableContainer
            component={Paper}
            sx={{
              overflowX: 'hidden',
              width: '100%',
              backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#fff',
              color: theme.palette.mode === 'dark' ? '#fff' : '#000',
              borderRadius: '10px',
              border: `1px solid ${theme.palette.mode === 'dark' ? '#444' : '#ccc'}`,
            }}
          >
            <Table size="small" sx={{ borderCollapse: 'collapse' }}>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: theme.palette.mode === 'dark' ? '#2e2e48' : '#e0e0e0',
                  }}
                >
                  <TableCell
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#f3ec78' : '#000',
                      fontWeight: theme.typography.fontWeightBold,
                      fontSize: isMobile ? '12px' : '12px',
                      textAlign: isMobile ? 'center' : 'center',
                      border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                      padding: '8px',
                    }}
                  >
                    {isMobile ? 'EX' : 'EXCHANGE'}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: theme.palette.mode === 'dark' ? '#f3ec78' : '#000',
                      fontWeight: theme.typography.fontWeightBold,
                      fontSize: isMobile ? '12px' : '12px',
                      textAlign: isMobile ? 'center' : 'center',
                      border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                      padding: '8px',
                    }}
                  >
                    {isMobile ? 'CO' : 'COMMODITY'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.mode === 'dark' ? '#f3ec78' : '#000',
                      fontWeight: theme.typography.fontWeightBold,
                      fontSize: isMobile ? '12px' : '12px',
                      border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                      padding: '8px',
                    }}
                  >
                    {isMobile ? 'BR' : 'BROKERAGE'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.mode === 'dark' ? '#f3ec78' : '#000',
                      fontWeight: theme.typography.fontWeightBold,
                      fontSize: isMobile ? '12px' : '12px',
                      border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                      padding: '8px',
                    }}
                  >
                    {isMobile ? 'IM' : 'INTRADAY MARGIN'}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.mode === 'dark' ? '#f3ec78' : '#000',
                      fontWeight: theme.typography.fontWeightBold,
                      fontSize: isMobile ? '12px' : '12px',
                      border: `1px solid ${theme.palette.mode === 'dark' ? '#555' : '#ddd'}`,
                      padding: '8px',
                    }}
                  >
                    {isMobile ? 'HM' : 'HOLDING MARGIN'}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Render the ExchangeMargin component here */}
                {user?.NSE.allow && <ExchangeMargin type="NSE" config={user?.NSE} />}
                {user?.MCX.allow && <ExchangeMargin type="MCX" config={user?.MCX} />}
                {user?.COMEX.allow && <ExchangeMargin type="COMEX" config={user?.COMEX} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>
      {isMobile && (
        <Typography
          variant="caption"
          my={4}
          sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#af4261', textAlign: 'center' }}
        >
          Ex-EXCHANGE || CO-COMMODITY || BR-BROKERAGE || IM-INTRADAY MARGIN || HM-HOLDING MARGIN
        </Typography>
      )}
    </>
  );
}
