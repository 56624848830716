import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// config
import { AuthContext } from '../contexts/JWTContext';
import {
  PATH_AFTER_LOGIN,
  PATH_AFTER_LOGIN_OPERATOR,
  PATH_AFTER_LOGIN_INSURER,
  PATH_AFTER_LOGIN_BANKER,
} from '../config';

// components
import LoadingScreen from '../components/LoadingScreen';
import BrokerList from '../pages/dashboard/insurer/BrokerList';
import BrokerNew from '../pages/dashboard/insurer/BrokerNew';
import ClientNew from '../pages/dashboard/insurer/ClientNew';
import ClientList from '../pages/dashboard/insurer/ClientList';
// import OperatorProfile from '../pages/dashboard/operator/OperatorProfile';
import { TradeProvider } from '../contexts/TradeContext';
import InstrumentList from '../pages/dashboard/InstrumentList';
import InstrumentListClient from '../pages/dashboard/InstrumentListClient';
import { PATH_DASHBOARD } from './paths';
import WatchlistRow from '../sections/@dashboard/Trade/watchlist/WatchListRow';
import AddToWatchlist from '../sections/@dashboard/Trade/watchlist/AddToWatchList';
import Portfolio from '../sections/@dashboard/Trade/portfolio/Portfolio';
import TradeHistory from '../sections/@dashboard/Trade/history/TradeHistor';
import RoleBasedGuard from '../guards/RoleBasedGuard';
import TradesPage from '../sections/@dashboard/Trade/trades';
import ScriptWiseTradeList from '../pages/dashboard/operator/ScriptWiseTradeList';
import UsersTradeListForScript from '../pages/dashboard/operator/UsersTradeListForScript';
import UsersBalanceSummaryTable from '../pages/dashboard/operator/UsersBalanceSummaryTable';
import ClientProfileWithTrade from '../pages/dashboard/operator/ClientProfileWithTrade';
import EquityLotSetting from '../sections/@dashboard/user/setting/EquityLotSetting';
import ActivityLogList from '../pages/dashboard/operator/ActivityLogTable';
import ClientLineTradeList from '../pages/dashboard/operator/ClientLineTradesList';
import UserFileList from '../pages/dashboard/operator/UserFileList';
import { AccountChangePassword } from '../sections/@dashboard/user/account';
import RiskyClientList from '../pages/dashboard/insurer/RiskyClientList';
import ComexWatchlistRow from '../pages/dashboard/operator/ComexWatchList';
import AddToComexWatchlist from '../pages/dashboard/operator/AddToComexWatchList';
import ExchangeTimingList from '../pages/dashboard/insurer/ExchangeTimingList';
import PriceChangeConfig from '../pages/dashboard/operator/PriceChangeConfig';
import PlHistorySummary from '../pages/dashboard/operator/PlHistorySummary';
import BanScriptsList from '../pages/dashboard/operator/BanScriptsList';
import MessageBroadcast from '../pages/dashboard/operator/MessageBroadCast';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pathname = useLocation();

  // const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname?.pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { userType } = useContext(AuthContext);
  const getPathAfterLogin = () => {
    let path = '/login';
    if (userType?.isSuperAdmin) {
      path = PATH_AFTER_LOGIN;
    } else if (userType?.isAdmin) {
      path = PATH_AFTER_LOGIN_INSURER;
    } else if (userType?.isBroker) {
      path = PATH_AFTER_LOGIN_OPERATOR;
    } else if (userType?.isClient) {
      path = PATH_AFTER_LOGIN_BANKER;
    }
    return path;
  };

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // SUPERADMIN DASHBOARD ROUTES

    {
      path: 'superadmin',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['superAdmin']} hasContent>
            <TradeProvider>
              <DashboardLayout />
            </TradeProvider>
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'dashboard',
          children: [
            {
              path: ':userId',
              children: [
                {
                  path: 'broker/:userId',
                  children: [
                    {
                      path: 'client/:clientId',
                      element: <ClientProfileWithTrade />,
                    },
                    {
                      path: '',
                      element: <UsersBalanceSummaryTable />,
                    },
                  ],
                },
                {
                  path: '',
                  element: <UsersBalanceSummaryTable />,
                },
              ],
            },
            {
              path: '',
              element: <UsersBalanceSummaryTable />,
            },
          ],
        },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/superadmin/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },

        //     { path: ':name/edit', element: <UserCreate /> },
        //     { path: 'account', element: <UserAccount /> },
        //   ],
        // },
        {
          path: 'reports',
          children: [
            { path: 'risky-users', element: <RiskyClientList /> },
            { path: 'exchange-timing', element: <ExchangeTimingList /> },
            { path: 'price-config', element: <PriceChangeConfig /> },
            { path: 'weekly', element: <UserFileList /> },
            { path: 'P&L-history', element: <PlHistorySummary /> },
            { path: 'ban-scripts', element: <BanScriptsList /> },
          ],
        },
        { path: 'message-broadcast', element: <MessageBroadcast /> },
        { path: 'log', element: <ActivityLogList /> },
        { path: 'profile', element: <UserAccount /> },
        {
          path: 'forwarder',
          children: [
            { element: <Navigate to="/superadmin/forwarder/list" replace />, index: true },
            { path: 'list', element: <ForwarderList /> },
            { path: ':id', element: <ForwarderDetails /> },
          ],
        },
        {
          path: 'insurer',
          children: [
            { element: <Navigate to="/superadmin/insurer/list" replace />, index: true },
            {
              path: 'list',
              element: <InsurerList />,
            },
            {
              path: 'new',
              element: <InsurerNew />,
            },
            {
              path: ':id',
              element: <InsurerDetails />,
            },
          ],
        },
        {
          path: 'client-applications',
          children: [
            { element: <Navigate to="/superadmin/client-applications/list" replace />, index: true },
            { path: 'list', element: <ClientApplicationsList /> },
            { path: ':id', element: <ClientApplicationDetails /> },
            { path: ':id/assign', element: <ClientApplicationAssign /> },
          ],
        },
        {
          path: 'admin',
          children: [
            // { element: <Navigate to="/superadmin/admin/list" replace />, index: true },
            {
              path: 'list',
              // element: <OperatorList />,
              children: [
                {
                  path: ':adminId',
                  // element: <BrokerList />,
                  children: [
                    {
                      path: ':brokerId',
                      element: <ClientList />,
                      children: [
                        {
                          path: ':clientId',
                          element: <ClientProfileWithTrade />,
                        },
                        { path: '', element: <BrokerList /> },
                      ],
                    },
                    { path: '', element: <OperatorList /> },
                  ],
                },
                { path: '', element: <OperatorList /> },
              ],
            },
            {
              path: 'new',
              element: <OperatorNew />,
            },
            {
              path: 'edit/:userId',
              element: <OperatorNew />,
            },
            {
              path: 'client/edit/:userId',
              element: <ClientNew />,
            },
            {
              path: 'client/duplicate/:userId',
              element: <ClientNew />,
            },
            // {
            //   path: ':id',
            //   element: <OperatorDetails />,
            // },
          ],
        },
        {
          path: 'instrument',
          children: [
            { element: <Navigate to="/superadmin/instrument/list" replace />, index: true },
            { path: 'list', element: <InstrumentList /> },
          ],
        },
        {
          path: 'project',
          children: [
            { element: <Navigate to="/superadmin/project/list" replace />, index: true },
            {
              path: 'list',
              element: <OperatorProjectList />,
            },
            {
              path: ':id',
              element: <OperatorProjectProfile />,
            },
          ],
        },
        {
          path: 'banker',
          children: [
            { element: <Navigate to="/superadmin/banker/list" replace />, index: true },
            {
              path: 'list',
              element: <BankerList />,
            },
            {
              path: 'new',
              element: <BankerNew />,
            },
            {
              path: ':id',
              element: <BankerDetails />,
            },
          ],
        },
        {
          path: 'client-loans',
          children: [
            { element: <Navigate to="/superadmin/client-loans/list" replace />, index: true },
            { path: 'list', element: <ClientApplicationsLoanList /> },
            { path: ':id', element: <ClientApplicationLoanDetails /> },
            { path: ':id/assign', element: <ClientApplicationLoanAssign /> },
          ],
        },
        {
          path: 'entries',
          children: [
            { element: <Navigate to="/superadmin/entries/list" replace />, index: true },
            { path: 'list', element: <EntriesList /> },
            { path: ':id', element: <MatchDataList /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/superadmin/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/superadmin/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // OPERATOR BROKER DASHBOARD ROUTES

    {
      path: 'broker',
      element: (
        <AuthGuard>
          <TradeProvider>
            <RoleBasedGuard roles={['broker']} hasContent>
              <DashboardLayout />
            </RoleBasedGuard>
          </TradeProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_OPERATOR} replace />, index: true },
        { path: 'app', element: <UsersBalanceSummaryTable /> },
        { path: 'profile', element: <UserAccount /> },
        {
          path: 'watchlist',
          children: [
            { element: <WatchlistRow />, index: true },
            { path: 'new', element: <AddToWatchlist /> },
          ],
        },
        {
          path: 'clients',
          children: [
            {
              path: 'trades',
              element: <OperatorProjectList />,
            },
          ],
        },
        {
          path: 'instrument',
          children: [
            { element: <Navigate to="/broker/instrument/list" replace />, index: true },
            { path: 'list', element: <InstrumentList /> },
          ],
        },
        {
          path: 'positions',
          children: [
            {
              path: 'grouped',
              children: [
                {
                  path: 'active',
                  children: [
                    {
                      path: ':scriptId',
                      element: <UsersTradeListForScript type="OPEN" />,
                    },
                    {
                      path: '',
                      element: <ScriptWiseTradeList type="OPEN" />,
                    },
                  ],
                },
                {
                  path: 'closed',
                  children: [
                    {
                      path: ':scriptId',
                      element: <UsersTradeListForScript type="ORDER" />,
                    },
                    {
                      path: '',
                      element: <ScriptWiseTradeList type="ORDER" />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'trades',
          children: [
            { path: 'executed', element: <OperatorProjectList type={'OPEN'} /> },
            { path: 'pending', element: <OperatorProjectList type={'PENDING'} /> },
          ],
        },
        {
          path: 'client',
          children: [
            // { path: ':id', element: <OperatorProfile /> },
            { path: '', element: <ClientList /> },
            { path: ':clientId', element: <ClientProfileWithTrade /> },
            {
              path: 'list',
              element: <ClientList />,
              children: [{ path: ':id', element: <ClientList />, index: true }],
            },
            {
              path: 'edit/:userId',
              element: <ClientNew path={PATH_DASHBOARD.operatorRole.clients.root} />,
            },
            {
              path: 'duplicate/:userId',
              element: <ClientNew />,
            },
            { path: 'new', element: <ClientNew path={PATH_DASHBOARD.operatorRole.clients.root} /> },

            { path: ':id/edit', element: <ProjectEdit /> },
            {
              path: 'trades',
              element: <OperatorProjectList />,
            },
          ],
        },
        {
          path: 'log',
          element: <ActivityLogList />,
        },
        {
          path: 'reports',
          children: [
            { path: 'weekly', element: <UserFileList /> },
            { path: 'risky-users', element: <RiskyClientList /> },
            { path: 'P&L-history', element: <PlHistorySummary /> },
            { path: 'ban-scripts', element: <BanScriptsList /> },
          ],
        },
        {
          path: 'line-trades',
          element: <ClientLineTradeList />,
        },
        {
          path: 'project',
          children: [
            { path: 'list', element: <ProjectList />, index: true },
            { path: 'new', element: <ProjectNew /> },
            { path: ':id', element: <ProjectView /> },
            { path: ':id/edit', element: <ProjectEdit /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { path: 'equity', element: <EquityLotSetting /> },
            { path: 'change-password', element: <AccountChangePassword /> },
          ],
        },
      ],
    },

    // ADMIN DASHBOARD ROUTES

    {
      path: 'admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['admin']} hasContent>
            <TradeProvider>
              <DashboardLayout />
            </TradeProvider>
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_INSURER} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'profile', element: <UserAccount /> },
        {
          path: 'watchlist',
          children: [
            { element: <WatchlistRow />, index: true },
            { path: 'new', element: <AddToWatchlist /> },
          ],
        },
        {
          path: 'tv-watchlist',
          children: [
            { element: <ComexWatchlistRow />, index: true },
            { path: 'new', element: <AddToComexWatchlist /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { path: 'equity', element: <EquityLotSetting /> },
            { path: 'change-password', element: <AccountChangePassword /> },
          ],
        },
        {
          path: 'reports',
          children: [
            { path: 'weekly', element: <UserFileList /> },
            { path: 'risky-users', element: <RiskyClientList /> },
            { path: 'P&L-history', element: <PlHistorySummary /> },
            { path: 'ban-scripts', element: <BanScriptsList /> },
          ],
        },
        {
          path: 'instrument',
          children: [
            { element: <Navigate to="/admin/instrument/list" replace />, index: true },
            { path: 'list', element: <InstrumentList /> },
          ],
        },
        {
          path: 'broker',
          children: [
            {
              path: 'list',
              // element: <BrokerList />,
              children: [
                {
                  path: ':id',
                  element: <ClientList />,
                  children: [{ path: ':clientId', element: <ClientProfileWithTrade /> }],
                },
                {
                  path: '',
                  element: <BrokerList />,
                },
              ],
            },
            { path: '', element: <BrokerList /> },
            { path: 'new', element: <BrokerNew /> },
            { path: 'client/new', element: <ClientNew /> },
            { path: 'client/:clientId', element: <ClientProfileWithTrade /> },
            {
              path: 'edit/:userId',
              element: <BrokerNew />,
            },
            {
              path: 'client/edit/:userId',
              element: <ClientNew />,
            },
            {
              path: 'client/duplicate/:userId',
              element: <ClientNew />,
            },

            { path: ':id/edit', element: <ProjectEdit /> },
          ],
        },
        {
          path: 'client',
          children: [
            { path: '', element: <ClientList /> },
            { path: 'list', element: <ClientList /> },
            { path: 'edit/:userId', element: <ClientNew /> },
            { path: ':clientId', element: <ClientProfileWithTrade /> },
          ],
        },
        {
          path: 'positions',
          children: [
            {
              path: 'grouped',
              children: [
                {
                  path: 'active',
                  children: [
                    {
                      path: ':scriptId',
                      element: <UsersTradeListForScript type="OPEN" />,
                    },
                    {
                      path: '',
                      element: <ScriptWiseTradeList type="OPEN" />,
                    },
                  ],
                },
                {
                  path: 'closed',
                  children: [
                    {
                      path: ':scriptId',
                      element: <UsersTradeListForScript type="ORDER" />,
                    },
                    {
                      path: '',
                      element: <ScriptWiseTradeList type="ORDER" />,
                    },
                  ],
                },
              ],
            },
            {
              path: ':userId',
              children: [
                {
                  path: 'grouped',
                  children: [
                    {
                      path: 'active',
                      children: [
                        {
                          path: ':scriptId',
                          element: <UsersTradeListForScript type="OPEN" />,
                        },
                        {
                          path: '',
                          element: <ScriptWiseTradeList type="OPEN" />,
                        },
                      ],
                    },
                    {
                      path: 'closed',
                      children: [
                        {
                          path: ':scriptId',
                          element: <UsersTradeListForScript type="ORDER" />,
                        },
                        {
                          path: '',
                          element: <ScriptWiseTradeList type="ORDER" />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'trades',
          children: [
            { path: 'executed', element: <OperatorProjectList type={'OPEN'} /> },
            { path: 'pending', element: <OperatorProjectList type={'PENDING'} /> },
          ],
        },
        {
          path: 'brokerlist',
          element: <UsersBalanceSummaryTable />,
          children: [{ path: ':userId', element: <UsersBalanceSummaryTable /> }],
        },
        {
          path: 'log',
          element: <ActivityLogList />,
        },
        {
          path: 'line-trades',
          element: <ClientLineTradeList />,
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
      ],
    },

    // CLIENTS DASHBOARD ROUTES

    {
      path: 'client',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['client']} hasContent>
            <TradeProvider>
              <DashboardLayout />
            </TradeProvider>
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN_BANKER} replace />, index: true },
        {
          path: 'watchlist',
          children: [
            { element: <WatchlistRow />, index: true },
            { path: 'new', element: <AddToWatchlist /> },
          ],
        },
        {
          path: 'tv-watchlist',
          children: [
            { element: <ComexWatchlistRow />, index: true },
            { path: 'new', element: <AddToComexWatchlist /> },
          ],
        },
        { path: 'app', element: <GeneralApp /> },
        { path: 'portfolio', element: <Portfolio /> },
        { path: 'history', element: <TradeHistory /> },
        { path: 'trades', element: <TradesPage /> },
        { path: 'profile', element: <UserAccount /> },
        {
          path: 'instrument',
          children: [
            { element: <Navigate to="/client/instrument/list" replace />, index: true },
            { path: 'list', element: <InstrumentListClient /> },
          ],
        },
        {
          path: 'loan',
          children: [
            { path: 'list', element: <LoanList />, index: true },
            { path: 'new', element: <ProjectNew /> },
            { path: ':id', element: <ProjectView /> },
            { path: ':id/edit', element: <ProjectEdit /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
      ],
    },

    // Error Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          // element: <Navigate to="/" replace />,
          element: <Navigate to={getPathAfterLogin()} replace />,
          index: true,
        },
        // { path: 'app', element: <GeneralApp /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));

// OPERATOR ROLE

const ProjectList = Loadable(lazy(() => import('../pages/dashboard/role/operator/projects/ProjectList')));
const ProjectNew = Loadable(lazy(() => import('../pages/dashboard/role/operator/projects/ProjectNew')));
const ProjectView = Loadable(lazy(() => import('../pages/dashboard/role/operator/projects/ProjectView')));
const ProjectEdit = Loadable(lazy(() => import('../pages/dashboard/role/operator/projects/ProjectEdit')));

// BANKER ROLE
const LoanList = Loadable(lazy(() => import('../pages/dashboard/role/banker/loan/LoanList')));

// ADMIN

// FORWARDER
const ForwarderList = Loadable(lazy(() => import('../pages/dashboard/forwarder/ForwarderList')));
const ForwarderDetails = Loadable(lazy(() => import('../pages/dashboard/forwarder/ForwarderDetails')));

// INSURER
const InsurerList = Loadable(lazy(() => import('../pages/dashboard/insurer/InsurerList')));
const InsurerNew = Loadable(lazy(() => import('../pages/dashboard/insurer/InsurerNew')));
const InsurerDetails = Loadable(lazy(() => import('../pages/dashboard/insurer/InsurerDetails')));
const ClientApplicationsList = Loadable(lazy(() => import('../pages/dashboard/insurer/ClientApplicationsList')));
const ClientApplicationDetails = Loadable(lazy(() => import('../pages/dashboard/insurer/ClientApplicationDetails')));
const ClientApplicationAssign = Loadable(lazy(() => import('../pages/dashboard/insurer/ClientApplicationAssign')));

// OPERATOR
const OperatorList = Loadable(lazy(() => import('../pages/dashboard/operator/OperatorList')));
const OperatorNew = Loadable(lazy(() => import('../pages/dashboard/operator/OperatorNew')));
const OperatorProjectList = Loadable(lazy(() => import('../pages/dashboard/operator/OperatorProjectList')));
const OperatorProjectProfile = Loadable(lazy(() => import('../pages/dashboard/operator/OperatorProjectProfile')));

// BANKER
const BankerList = Loadable(lazy(() => import('../pages/dashboard/banker/BankerList')));
const BankerNew = Loadable(lazy(() => import('../pages/dashboard/banker/BankerNew')));
const BankerDetails = Loadable(lazy(() => import('../pages/dashboard/banker/BankerDetails')));
const ClientApplicationsLoanList = Loadable(lazy(() => import('../pages/dashboard/banker/ClientApplicationsLoanList')));
const ClientApplicationLoanDetails = Loadable(
  lazy(() => import('../pages/dashboard/banker/ClientApplicationLoanDetails'))
);
const ClientApplicationLoanAssign = Loadable(
  lazy(() => import('../pages/dashboard/banker/ClientApplicationLoanAssign'))
);

// ENTRIES
const EntriesList = Loadable(lazy(() => import('../pages/dashboard/entries/EntriesList')));
const MatchDataList = Loadable(lazy(() => import('../pages/dashboard/entries/MatchDataList')));

// USER ACCOUNT
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
