/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Typography, CardContent, Grid, Modal, Paper, IconButton, Button } from '@mui/material';
import { Close, OpenInFullOutlined, PermIdentity } from '@mui/icons-material';
import { fColorNumber, fNumber } from '../../../utils/formatNumber';

SummaryView.propTypes = {
  summary: PropTypes.object.isRequired,
  onActiveBuySellClick: PropTypes.func,
  currency: PropTypes.string.isRequired,
};

const ALL_EXCHANGES = ['NSE', 'MCX', 'COMEX', 'NSEIX', 'WINDEX'];

export default function SummaryView({ summary, onActiveBuySellClick, currency }) {
  const mainKeys = ['your', 'total', 'admin'];
  const subKeys = ['grossProfitLoss', 'activeProfitLoss', 'profitLoss', 'brokerage'];
  const role = summary?.user?.role?.rolename;
  let modifiedMainKey = [];
  if (role === 'broker') {
    modifiedMainKey = ['broker', 'client', 'admin'];
  }
  if (role === 'admin') {
    modifiedMainKey = ['admin', 'broker', 'superAdmin'];
  }
  if (role === 'superAdmin') {
    modifiedMainKey = ['superAdmin', 'admin'];
    mainKeys.splice(-1, 1);
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [currentMainKey, setCurrentMainKey] = useState(null);
  const [currentMainIndex, setCurrentMainIndex] = useState(0);
  const [showAllExchanges, setShowAllExchanges] = useState(false);

  const EXCHANGES =
    currency.toLowerCase() === 'usd'
      ? ALL_EXCHANGES.filter((ex) => ['COMEX', 'WINDEX'].includes(ex))
      : ALL_EXCHANGES.filter((ex) => ['NSE', 'MCX', 'NSEIX', 'WINDEX'].includes(ex));

  const handleOpenModal = (index, mainKey) => {
    setCurrentMainKey(mainKey);
    setCurrentMainIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentMainKey(null);
  };

  // Helper function to sum up values across exchanges
  const sumExchangeValues = (subKey, mainKey) =>
    EXCHANGES.reduce((sum, exchange) => sum + (summary[exchange]?.[subKey]?.[mainKey] || 0), 0);

  const toggleShowAllExchanges = () => {
    setShowAllExchanges(!showAllExchanges);
  };

  const displayedExchanges = showAllExchanges ? EXCHANGES : EXCHANGES.slice(0, 1);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {mainKeys.map((mainKey, i) => (
          <Grid item key={mainKey} xs={12} md={3}>
            <Card
              sx={{
                backgroundColor: i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
              }}
            >
              <CardContent
                sx={{
                  backgroundColor:
                    i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                }}
              >
                <Box
                  sx={{
                    textAlign: 'left',
                    padding: 1,
                    alignItems: 'center',
                    position: 'relative',
                    backgroundColor:
                      i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                  }}
                >
                  <Box display="flex" flexDirection="row" alignItems={'center'}>
                    <PermIdentity sx={{ mr: 1 }} />
                    <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
                      {modifiedMainKey[i]}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => handleOpenModal(i, mainKey)}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <OpenInFullOutlined />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor:
                      i === 0 ? 'dashboard.primary' : i === 1 ? 'dashboard.secondary' : 'dashboard.ternary',
                  }}
                >
                  {subKeys.map((subKey) => (
                    <Box
                      key={subKey}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: subKey === 'brokerage' ? 'text' : fColorNumber(sumExchangeValues(subKey, mainKey)),
                        }}
                      >
                        {fNumber(sumExchangeValues(subKey, mainKey))}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {subKey === 'brokerage' && 'Brokerage'}
                        {subKey === 'profitLoss' && 'Booked P/L'}
                        {subKey === 'activeProfitLoss' && 'Active P/L'}
                        {subKey === 'grossProfitLoss' && 'Gross P/L'}
                      </Typography>
                    </Box>
                  ))}
                  {modifiedMainKey[i] === 'client' && !!summary.brokerage.refund && (
                    <Box
                      key={'refund brokerage'}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: 1,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: 'text',
                        }}
                      >
                        {fNumber(
                          EXCHANGES.reduce((sum, exchange) => sum + (summary[exchange]?.brokerage?.refund || 0), 0)
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {'Refund Brokerage'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} md={3}>
          <Paper
            elevation={3}
            sx={{ p: 2, flex: 1, marginBottom: 2, backgroundColor: 'background.newneutral', cursor: 'pointer' }}
            // onClick={onActiveBuySellClick}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: '500', cursor: 'pointer' }}
              gutterBottom
              onClick={onActiveBuySellClick}
            >
              Active Buy
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h5">{fNumber(summary?.activeBuy || 0)}</Typography>
              <Box>
                {displayedExchanges.map((exchange) => (
                  <Typography key={exchange} variant="body1">
                    {exchange}: {fNumber(summary?.[exchange]?.activeBuy || 0)}
                  </Typography>
                ))}
                {EXCHANGES.length > 1 && (
                  <Button onClick={toggleShowAllExchanges}>{showAllExchanges ? 'Show Less' : 'More'}</Button>
                )}
              </Box>
            </Box>
          </Paper>
          <Paper elevation={3} sx={{ p: 1, flex: 1, backgroundColor: 'background.newneutral', cursor: 'pointer' }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: '500', cursor: 'pointer' }}
              gutterBottom
              onClick={role !== 'superAdmin' ? onActiveBuySellClick : undefined}
            >
              Active Sell
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Typography variant="h5">{fNumber(summary?.activeSell || 0)}</Typography>
              <Box>
                {displayedExchanges.map((exchange) => (
                  <Typography key={exchange} variant="body1">
                    {exchange}: {fNumber(summary?.[exchange]?.activeSell || 0)}
                  </Typography>
                ))}
                {EXCHANGES.length > 2 && (
                  <Button onClick={toggleShowAllExchanges}>{showAllExchanges ? 'Show Less' : 'More'}</Button>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', md: 700 },
            backgroundColor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 3,
            }}
          >
            <Typography variant="h4" textTransform={'uppercase'}>
              {modifiedMainKey[currentMainIndex]} Details
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          <Box>
            {EXCHANGES.map((exchange) => (
              <Box key={exchange} sx={{ mb: 2 }}>
                <Typography variant="h6">{exchange}</Typography>
                {subKeys.map((subKey) => (
                  <Box key={`${exchange}-${subKey}`} sx={{ display: 'flex', justifyContent: 'space-between', ml: 2 }}>
                    <Typography>{subKey}:</Typography>
                    <Typography>{fNumber(summary[exchange]?.[subKey]?.[currentMainKey] || 0)}</Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
