/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { io } from 'socket.io-client';
import { Box, IconButton, Typography, CircularProgress, useMediaQuery } from '@mui/material';
import { AddCircle, DriveFileRenameOutline } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useAuth from '../../../hooks/useAuth';

const ComexWatchlistRow = () => {
  const navigate = useNavigate();
  const [watchlistData, setWatchlistData] = useState({});
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useAuth();

  const handleWatchListClick = () => {
    if (user.role.rolename === 'client') {
      navigate(PATH_DASHBOARD.bankerRole.tradingView.add);
    }
    if (user.role.rolename === 'admin') {
      navigate(PATH_DASHBOARD.insurerRole.tradingView.add);
    }
    if (user.role.rolename === 'broker') {
      navigate(PATH_DASHBOARD.operatorRole.tradingView.add);
    }
  };

  useEffect(() => {
    const tradingView = JSON.parse(localStorage.getItem('TradingView') || '[]');

    if (!tradingView || tradingView.length === 0) {
      console.warn('No TradingView data found in localStorage');
      setLoading(false);
      return;
    }

    const socket = io('wss://tv-socket-dev.devqix.in', {
      transports: ['websocket'], // Use WebSocket transport
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
      socket.emit('subscribe', tradingView);
      console.log('Subscribed with:', tradingView);
    });

    socket.on('priceUpdate', (message) => {
      setWatchlistData((prevData) => {
        const updatedData = { ...prevData };
        const updates = Array.isArray(message) ? message : [message];

        updates.forEach((item) => {
          if (tradingView.includes(item.instrument_token)) {
            const prevItem = updatedData[item.instrument_token];

            // Set the color logic for Buy, Sell, LTP
            const getColor = (current, previous) => {
              if (current > previous) return 'blue';
              if (current < previous) return 'red';
              return 'black';
            };

            updatedData[item.instrument_token] = {
              ...prevItem, // Preserve previous values
              ...item, // Update with new values
              buyColor: getColor(item.depth?.buy?.[0]?.price || 0, prevItem?.depth?.buy?.[0]?.price || 0),
              sellColor: getColor(item.depth?.sell?.[0]?.price || 0, prevItem?.depth?.sell?.[0]?.price || 0),
              ltpColor: getColor(item.last_price || 0, prevItem?.last_price || 0),
            };
          }
        });

        return updatedData;
      });

      setLoading(false);
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket');
    });

    // eslint-disable-next-line consistent-return
    return () => {
      socket.disconnect();
    };
  }, []);

  const renderRows = () =>
    Object.values(watchlistData).map((item) => (
      <Box
        key={item.instrument_token}
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          padding: '16px',
          border: '1px solid #ddd',
          borderRadius: '8px',
          marginBottom: '12px',
          backgroundColor: '#f9f9f9',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          '&:hover': { backgroundColor: '#f0f0f0' },
        }}
      >
        {/* First Row: ScriptName, Buy, Sell */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="body1" sx={{ flex: 1, fontWeight: 'bold' }}>
            {item.instrument_token.split(':')[1]}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              color: item.buyColor || 'black',
              fontWeight: 'bold',
            }}
          >
            {item.depth?.buy?.[0]?.price || 'N/A'}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              color: item.sellColor || 'black',
              fontWeight: 'bold',
            }}
          >
            {item.depth?.sell?.[0]?.price || 'N/A'}
          </Typography>
        </Box>

        {/* Second Row: LTP, Change */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: isMobile ? 'space-between' : 'center',
            marginTop: isMobile ? '8px' : '0',
            width: '100%',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              color: item.ltpColor || 'black',
              fontWeight: 'bold',
            }}
          >
            {isMobile ? `LTP: ${item.last_price || 'N/A'}` : item.last_price || 'N/A'}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {isMobile ? `Change: ${item.change || 'N/A'}` : item.change || 'N/A'}
          </Typography>
        </Box>
      </Box>
    ));

  return (
    <Box sx={{ px: 2, py: 4, backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      {/* Header */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '16px',
          backgroundColor: '#6200ea',
          color: '#fff',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <IconButton onClick={handleWatchListClick} sx={{ color: '#fff' }}>
          <AddCircle />
        </IconButton>

        <Typography variant="h6" fontWeight="bold">
          TRADINGVIEW WATCHLIST
        </Typography>

        <IconButton sx={{ color: '#fff' }}>
          <DriveFileRenameOutline fontSize="large" />
        </IconButton>
      </Box>

      {/* Table Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'row' : 'row', // Keep horizontal layout even on mobile
          flexWrap: 'wrap', // Allow wrapping for mobile
          padding: '8px 16px',
          fontWeight: 'bold',
          borderBottom: '2px solid #ddd',
          backgroundColor: '#f4f4f4', // Light background for header
          marginBottom: '12px',
        }}
      >
        {/* ScriptName Header */}
        <Typography
          variant="body1"
          sx={{
            flex: 1,
            textAlign: isMobile ? 'center' : 'center',
            padding: '4px',
            fontSize: isMobile ? '16px' : '18px', // Increase font size
            fontWeight: 'bold', // Ensure bold font weight
            minWidth: isMobile ? '100px' : 'auto', // For mobile, allow space for label
          }}
        >
          Name
        </Typography>

        {/* Buy Header */}
        <Typography
          variant="body1"
          sx={{
            flex: 1,
            textAlign: 'center',
            padding: '4px',
            fontSize: isMobile ? '16px' : '18px', // Increase font size
            fontWeight: 'bold', // Ensure bold font weight
            minWidth: isMobile ? '100px' : 'auto', // For mobile, allow space for label
          }}
        >
          Buy
        </Typography>

        {/* Sell Header */}
        <Typography
          variant="body1"
          sx={{
            flex: 1,
            textAlign: 'center',
            padding: '4px',
            fontSize: isMobile ? '16px' : '18px', // Increase font size
            fontWeight: 'bold', // Ensure bold font weight
            minWidth: isMobile ? '100px' : 'auto', // For mobile, allow space for label
          }}
        >
          Sell
        </Typography>

        {/* LTP Header (Visible only on larger screens) */}
        {!isMobile && (
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              padding: '4px',
              fontSize: '18px', // Increase font size
              fontWeight: 'bold', // Ensure bold font weight
            }}
          >
            LTP
          </Typography>
        )}

        {/* Change Header (Visible only on larger screens) */}
        {!isMobile && (
          <Typography
            variant="body1"
            sx={{
              flex: 1,
              textAlign: 'center',
              padding: '4px',
              fontSize: '18px', // Increase font size
              fontWeight: 'bold', // Ensure bold font weight
            }}
          >
            Change
          </Typography>
        )}
      </Box>

      {/* Data Rows */}
      {loading ? (
        <Box display="flex" justifyContent="center" sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : Object.keys(watchlistData).length > 0 ? (
        <Box>{renderRows()}</Box>
      ) : (
        <Typography variant="body1" textAlign="center" sx={{ my: 5 }}>
          No data available in the watchlist.
        </Typography>
      )}
    </Box>
  );
};

export default ComexWatchlistRow;
