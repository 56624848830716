import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, TextField, Box, Typography, Tooltip } from '@mui/material';
import Label from '../Label';

const RHFAutoSelect = ({
  name,
  options,
  labelField = 'name',
  secondaryField = null,
  secondaryFieldPath = null,
  formatLabel = null,
  ...textFieldProps
}) => {
  const { control } = useFormContext();

  const getNestedValue = (obj, path) => {
    if (!path) return null;
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const getOptionLabel = (option) => {
    const rawLabel = option[labelField] || '';
    const primaryLabel = formatLabel ? formatLabel(rawLabel) : rawLabel;
    const secondaryLabel = secondaryFieldPath ? getNestedValue(option, secondaryFieldPath) : option[secondaryField];

    return secondaryLabel ? `${primaryLabel} ${secondaryLabel}` : primaryLabel;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: 'Please select a value',
      }}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={getOptionLabel}
          onChange={(event, value) => field.onChange(value)}
          error={fieldState.error ? fieldState.error : undefined}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          renderOption={(props, option) => {
            const primaryText = formatLabel ? formatLabel(option[labelField]) : option[labelField];

            return (
              <Box
                component="li"
                {...props}
                sx={{
                  display: 'grid !important',
                  gridTemplateColumns: '2fr 1fr',
                  width: '100%',
                  alignItems: 'center',
                  gap: 2,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <Tooltip title={primaryText} placement="top-start">
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      pl: 1,
                      cursor: 'pointer',
                    }}
                  >
                    {primaryText}
                  </Typography>
                </Tooltip>

                {(secondaryField || secondaryFieldPath) && (
                  <Box sx={{ justifySelf: 'end', pr: 1 }}>
                    <Label
                      variant="ghost"
                      color="info"
                      sx={{
                        minWidth: 'fit-content',
                        textAlign: 'center',
                      }}
                    >
                      {secondaryFieldPath ? getNestedValue(option, secondaryFieldPath) : option[secondaryField]}
                    </Label>
                  </Box>
                )}
              </Box>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textFieldProps}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ''}
              SelectProps={{ native: true }}
            />
          )}
          ListboxProps={{
            sx: {
              '& .MuiAutocomplete-option': {
                padding: '8px 4px',
              },
            },
          }}
        />
      )}
    />
  );
};

RHFAutoSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  labelField: PropTypes.string,
  secondaryField: PropTypes.string,
  secondaryFieldPath: PropTypes.string,
  formatLabel: PropTypes.func,
};

export default RHFAutoSelect;
